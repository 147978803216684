<template>
  <div id="app">
    <h2>Jackson non-exercise vo2max prediction</h2>
    <p>An online calculation of the BMI-formula specified in
      <a href="https://pubmed.ncbi.nlm.nih.gov/2287267/">
        Prediction of functional aerobic capacity without exercise testing
      </a>
    </p>
    <Form />
  </div>
</template>

<script>
import Form from './components/form.vue'

export default {
  name: 'app',
  components: {
    Form
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
