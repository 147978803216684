<template>
  <div id="form">
    <div class="flex-container">
      <div class="input">
        <h3>Weight in kg:</h3>
        <div class="number">
          <el-input v-model="weight"></el-input>
        </div>
      </div>
      <div class="input">
        <h3>Height in cm:</h3>
        <div class="number">
          <el-input v-model="height"></el-input>
        </div>
      </div>
      <div class="input">
        <h3>Age:</h3>
        <div class="number">
          <el-input v-model="age"></el-input>
        </div>
      </div>
    </div>
    <div class="input">
      <h3>Gender:</h3>
      <div class="radio">
        <el-radio-group v-model="gender">
          <el-radio label="M">Male</el-radio>
          <el-radio label="F">Female</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="input">
      <h3>Physical Activity Rating:</h3>
      <div class="par-container radio">
        <h5>Select the number that best describes your overall level of physical activity for the previous 6 MONTHS:</h5>
        <div :key="section.title" v-for="section in parSectionOptions">
          <h5>{{ section.title }}</h5>
          <el-radio-group v-model="par">
            <div class="par-option">
              <el-radio :key="option.value" v-for="option in section.options" :label="option.value">{{ option.label }}</el-radio>
            </div>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div class="result" v-if="vo2max">
      <h3>Predicted vo2max: {{ vo2max }} ml/kg/min</h3>
    </div>
    <el-button @click="calculate">Calculate</el-button>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {},

  data () {
    return {
      weight: undefined,
      height: undefined,
      age: undefined,
      gender: undefined,
      vo2max: undefined,
      par: undefined
    }
  },

  computed: {
    bmi () {
      return parseFloat(this.weight) / (parseFloat(this.height)/100 * parseFloat(this.height)/100);
    },

    genderIndex () {
      if (this.gender === 'M') {
        return 1;
      } else if (this.gender === 'F') {
        return 0;
      }
      return null;
    },

    parSectionOptions () {
      return [
        {
          title: 'Does not participate regularly in programed recreation, sport, or physical activity.',
          options: [
            {
              value: '0',
              label: '0: Avoids walking or exercise (for example, always uses elevators, drives whenever possible instead of walking).'
            },
            {
              value: '1',
              label: '1: Walks for pleasure, routinely uses stairs, occasionally exercises sufficiently to cause heavy breathing or perspiration.'
            }
          ]
        },
        {
          title: 'Participates regularly in recreation or work requiring modest physical activity (such as golf, horseback riding, calisthenics, gymnastics, table tennis, bowling, weight lifting, or yard work).',
          options: [
            {
              value: '2',
              label: '2: 10–60 minutes per week'
            },
            {
              value: '3',
              label: '3: Over 1 hour per week'
            }
          ]
        },
        {
          title: 'Participates regularly in heavy physical exercise (such as running or jogging, swimming, cycling, rowing, skipping rope, running in place) or engages in vigorous aerobic type activity (such as tennis, basketball, or handball).',
          options: [
            {
              value: '4',
              label: '4: Runs less than 1 mile per week or spends less than 30 minutes per week in comparable physical activity.'
            },
            {
              value: '5',
              label: '5: Runs 1–5 miles per week or spends 30–60 minutes per week in comparable physical activity.'
            },
            {
              value: '6',
              label: '6: Runs 5–10 miles per week or spends 1–3 hours per week in comparable physical activity.'
            },
            {
              value: '7',
              label: '7: Runs more than 10 miles per week or spends more than 3 hours per week in comparable physical activity.'
            }
          ]
        }
      ];
    }
  },

  methods: {
    calculate () {
      let result = 56.363 + 1.921 * parseFloat(this.par) - 0.381 * parseFloat(this.age) - 0.754 * this.bmi + 10.987 * this.genderIndex;
      result = Math.round(result * 10) / 10;
      this.vo2max = result;
    }
  }
}
</script>

<style lang="stylus" scoped>

#form
  max-width: 600px
  margin: auto

.input
  width: 100%
  margin: auto
  margin-top: 1.5em

.flex-container
  display: flex

  .input
    width: auto
    flex: 1 33%

.number
  margin: auto
  margin-top: 0.5em
  width: 80px

.radio
  margin: auto
  margin-top: 0.5em

.select
  margin: auto
  margin-top: 0.5em

.el-button
  margin-top: 1.5em

.el-radio-button
  width: 100%
  display: block

.par-container
  text-align: left

.par-option
  .el-radio
    display: block

.result
  margin-top: 1.5em
  width: 100%
  padding: 20px
  border-radius: 14px
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.03)
  border: solid 1px #e3e3e3
  background-color: #ffffff

</style>
